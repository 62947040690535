<template>
    <div class="profile_notes">
        <div class="title">
            <h3>Habit</h3>
            <div class="right_side">
                <div class="search_panel mr-3">
                    <div class="form-field">
                        <input type="text" name="search" placeholder="Search...">
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <PageItem/>
            </div>
        </div>            
        <ul class="card_listing">
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4 class="mb-3">Test Habit:</h4>
                    <h5>Submissions</h5>
                    <p>0/1</p>
                    <h5>Assigned on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4 class="mb-3">Test1 Habit:</h4>
                    <h5>Submissions</h5>
                    <p>0/1</p>
                    <h5>Assigned on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4 class="mb-3">Test2 Habit:</h4>
                    <h5>Submissions</h5>
                    <p>0/1</p>
                    <h5>Assigned on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                </NotesCard>
            </li>
        </ul>
        <div v-if="viewHabit" class="edit_modal">
            <div class="edit_section p-0">
                <div class="form_body">
                    <h2 class="title_header">Habit report<button class="close_btn" @click="viewHabit = false"><i class="fas fa-times"></i></button></h2>
                    <div class="form_content">
                        <div class="habitCard">
                            <div class="header">
                                <div class="user_info">
                                    <img src="@/assets/images/table-user.png" alt="">
                                    <div>
                                        <h6>John Doe</h6>
                                        <p>Started Tracking</p>
                                    </div>
                                </div>
                                <div class="submission">
                                    <h6>0/2</h6>
                                    <p>Submission</p>
                                </div>
                            </div>
                            <ul class="tracking_list">
                                <li>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.5);">50<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="50"/>
                                        </svg>
                                    </div>
                                    <h6>Compliance Score: <span>50%</span></h6>
                                </li>
                                <li>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.5);">8<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="92"/>
                                        </svg>
                                    </div>
                                    <h6>Habit Score: <span>8%</span></h6>
                                </li>
                                <li>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.5);">2<span>Wk</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="50"/>
                                        </svg>
                                    </div>
                                    <h6>Best Streak: <span>2 Weeks</span></h6>
                                </li>
                            </ul>
                            <ul class="habit_list">
                                <li>
                                    <h3 class="sub_header m-0">Movement</h3>
                                    <div class="mt-3">
                                        <HabitCards v-bind:options="habitObj" title="Pellentesque venenatis ac libero ut venenatis?"/>
                                    </div>
                                    <div class="mt-3">
                                        <HabitCards v-bind:options="habitObj" title="Mauris eget nisl blandit elit varius ornare?"/>
                                    </div>
                                </li>
                            </ul>
                            <ul class="proofs">
                                <li>Submitted Proofs: --</li>
                                <li>History: --</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pagination mt-2">
            <ul>
                <li>1</li>
                <li class="active">2</li>
                <li>3</li>
                <li>4</li>
                <li><i class="fas fa-ellipsis-h"></i></li>
            </ul>
        </div>
</template>

<script>
import PageItem from './PageItem.vue';
import NotesCard from './NotesCard.vue';
import HabitCards from './HabitCards.vue'
export default{
    name: 'ProfileHabit',
    components:{
        PageItem,
        NotesCard,
        HabitCards
    },
    data(){
        return{
            viewHabit: false,
            actionObj: [
                {
                    icon: "fa-search-plus",
                    label: "View",
                },
            ],
            habitObj: [
                {
                    label: "Best Streak",
                    value: "0 Days",
                },
                {
                    label: "Habit Score",
                    value: "0%",
                },
            ]
        }
    },
    methods:{
        editFunc(){
            this.viewHabit = true;
        }
    }
}
</script>
